import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Services from '../../api/service'
import logofooter from '../../images/footer-logo.png'
import orizonic from '../../images/orizinic-logo.svg'
import { fetchItem } from '../../utils/fetchItem'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}
const SubmitHandler = (e) => {
    e.preventDefault()
}

const Footer = (props) => {

    const [address, setAddress] = useState({});
    const [contact, setContact] = useState({});
    fetchItem('/user/general/details', setContact)
    fetchItem('/user/general/location', setAddress);
    return (
        <footer className="footer-section">
            <div className="upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget subscribe">
                               
                               <Link className='logo-1' onClick={ClickHandler} to="/home"><img src={logofooter} alt="logo" /></Link>
                                <p>We are pleased to introduce ourselves as one of the leading companies specializing in the field of flexible fluid transfer products.</p>
                               
                                
                                <div className="social">
                                    <ul>
                                        <li><Link onClick={ClickHandler} to="/"><i className="icon-35"></i></Link></li>
                                        <li><Link onClick={ClickHandler} to="/"><i className="icon-32"></i></Link></li>
                                        <li><Link onClick={ClickHandler} to="/"><i className="icon-34"></i></Link></li>
                                        <li><Link onClick={ClickHandler} to="/"><i className="icon-33"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-xl-3 offset-xl-1 col-lg-2 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Quick Link</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">Home</Link></li>
                                    <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/products">Products</Link></li>
                                    <li><Link onClick={ClickHandler} to="/services">Services</Link></li>
                                    <li><Link onClick={ClickHandler} to="/brands">Brands</Link></li>
                                    <li><Link onClick={ClickHandler} to="/gallery">Gallery</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Contact Info</h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <li>{address[0]?.address}</li>
                                        <li>{contact[0]?.manager_number} </li>
                                        <li>{contact[0]?.workshop_number}</li>
                                        <li>{contact[0]?.sales_number}</li>
                                        <li>{contact[0]?.email_one}</li>
                                        <li>{contact[0]?.email_two}</li>
                                        <li>sales@hydropoweroilfield.abudhabi</li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="lower-footer">
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col col-lg-6 col-12">
                            <div className="copy-right">
                                <p className="copyright"> Copyright &copy; 2024 <Link onClick={ClickHandler} to="/home">Hydro Power Oil Field Abudhabi </Link> All rights
                                    reserved.</p>
                            </div>
                        </div>

                        <div className="col col-lg-6 col-12">
                            <ul className="lower-footer-link">
                                <li> Designed and Developed by <a target="_blank" href="https://orizonic.com/"> <img className='orizonic' src={orizonic} alt="orizonic" /></a></li>
                            </ul>
                        </div>
                       
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;