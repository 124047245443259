import React, { useEffect, useState } from 'react';
import ContactForm from '../ContactFrom/ContactForm'
import { Link } from 'react-router-dom'
import { axiosClient } from '../../config/axios.config';
import { fetchItem } from '../../utils/fetchItem';

const Contactpage = () => {
    const [contact, setContact] = useState({});

    const [generalDetails, setGeneralDetails] = useState({});
    const [address, setAddress] = useState({});
    fetchItem('/user/general/location', setAddress);
    useEffect(() => {
        (async () => {
         await axiosClient.get('/user/general/details').then((response) => {
            setGeneralDetails(response.data.data[0])
         })
        })()
    },[])

    return (
        <section className="contact-pagg-section">
         
            <div className="contact-page separator-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="contact-area-wrapper">
                                <div className="contact-form-area">
                                    <h2>Drop A Line!</h2>
                                    <ContactForm />
                                </div>
                            </div>
                        </div>

{/* <div className="col col-lg-7">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Contact Info</h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <li>{address[0]?.address}</li>
                                        <li>{contact[0]?.manager_number} </li>
                                        <li>{contact[0]?.workshop_number}</li>
                                        <li>{contact[0]?.sales_number}</li>
                                        <li>{contact[0]?.email_one}</li>
                                        <li>{contact[0]?.email_two}</li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}

                        <div className="col col-lg-5">
                            <div className="office-info">
                                <div className="heading-title">
                                    <small>Contact us</small>
                                    <h2><span>Get in touch!</span></h2>
                                    <p>{address.address}</p>
                                    <div className="contact-info">
                                        <ul>
                                        
                                            <li><i className="icon-29"></i> {address[0]?.address} </li>
                                            <li><i className="icon-31"></i> Manager : {generalDetails.manager_number} </li>
                                            <li><i className="icon-31"></i> Workshop: {generalDetails.workshop_number} </li>
                                            <li><i className="icon-31"></i> Sales : {generalDetails.sales_number} </li>
                                            <li><i className="icon-31"></i> Land line : {generalDetails.landline} </li>
                                            <li><i className="icon-30"></i> Email : {generalDetails.email_one}</li>
                                            <li><i className="icon-30"></i> Email : {generalDetails.email_two}</li>
                                            <li><i className="icon-30"></i> Email : sales@hydropoweroilfield.abudhabi</li>

                                        </ul>
                                    </div>
                                    <div className="info-social">
                                        <ul>
                                            <li><Link to="/contact" ><i className="icon-35"></i></Link></li>
                                            <li><Link to="/contact" ><i className="icon-32"></i></Link></li>
                                            <li><Link to="/contact" ><i className="icon-34"></i></Link></li>
                                            <li><Link to="/contact" ><i className="icon-33"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="map-section ">
                <h2 className="hidden">Contact map</h2>
                <div className="map">
                    {/* <iframe title='map'
                        src={address.location_map_url}
                        allowfullscreen></iframe> */}

<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3634.9522203572383!2d54.47601647504148!3d24.348156965566385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e41abd1a189c1%3A0x4f2b2c7ad5510cc3!2sHydropower%20Oilfield%20Equipment%20Spare%20Parts%20LLC!5e0!3m2!1sen!2sin!4v1709132018818!5m2!1sen!2sin" width="100%" height="550" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
               
                </div>
            </div>


        </section>
    )

}

export default Contactpage;
