import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import backShape from '../../images/about/tshape.svg'
import abimg2 from '../../images/about/about-img4.jpg'
import { fetchItem } from '../../utils/fetchItem'

const About2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [about, setAbout] = useState([]);

    fetchItem('/user/general/about', setAbout);

    return (
        <section className="about-section-s2">
            <div className="back-shape"
                style={{ backgroundImage: `url(${backShape})` }}>
            </div>
            <div className="container">

                <div className="about-bottom-wrap">
                    <div className="row">
                        <div className="col-lg-7 col-md-12 col-12">
                            <div className="about-bottom-left">
                                <div className="heading-title">
                                    <small>About Company</small>
                                    <h2>Hydro Power  <span>{about[0]?.about_heading}</span></h2>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-12">
                                        <div className="about-bottom-left-text">
                                            {/* <p>Hydro Power Oil-field equipment spare parts LLC is one of the leading companies in Abu Dhabi, U.A.E dealing and specialized in the field of Fluid Transfer products and services We are in the market, successfully conducting business in the United Arab Emirates for the past fifteen years, with four associated companies</p> */}

                                            <p>{about[0]?.home_page_about}</p>


                                            <Link onClick={ClickHandler} to="/about">More About Us</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-12">
                            <div className="about-bottom-right">
                                <div className="about-bottom-right-img">
                                    <img src={abimg2} alt="" />

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </section>
    )
}

export default About2;