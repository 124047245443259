import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import HomePage2 from '../HomePage2/HomePage2';
import AboutPage from '../AboutPage/AboutPage';
import ServicePage from '../ServicePage/ServicePage';
import ServiceSinglePage from '../ServiceSinglePage/ServiceSinglePage';
import ProjectPage from '../ProjectPage/ProjectPage';
import ProjectPageS2 from '../ProjectPageS2/ProjectPageS2';
import ProjectPageS4 from '../ProjectPageS4/ProjectPageS4';
import ProjectSinglePage from '../ProjectSinglePage/ProjectSinglePage';
import TeamPage from '../TeamPage/TeamPage';
import TeamSinglePage from '../TeamSinglePage/TeamSinglePage';
import ContactPage from '../ContactPage/ContactPage';
import ErrorPage from '../ErrorPage/ErrorPage';
import Brands from '../Brands/Brands';
import Gallerypage from '../Gallery/Gallery';
import SubCategoryListing from '../ProductListingBySubCat';
import SingleproductPage from '../SingleProduct';


const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage2 />} />
          {/* <Route path="home" element={<Homepage />} />
          <Route path="home-2" element={<HomePage2 />} />
          <Route path="home-3" element={<HomePage3 />} /> */}
          <Route path="about" element={<AboutPage />} />
          <Route path="products" element={<TeamPage />} />
          <Route path="products/:slug" element={<TeamSinglePage />} />      
          <Route path="products/subcategory/:slug" element={<SubCategoryListing />} />      
          <Route path='products/single/:id' element={<SingleproductPage/>}/>         
          <Route path="service" element={<ServicePage />} />
          <Route path="service-single/:slug" element={<ServiceSinglePage />} />
          <Route path="projects" element={<ProjectPage />} />
          <Route path="projects-s2" element={<ProjectPageS2 />} />       
          <Route path="projects-s4" element={<ProjectPageS4 />} />
          <Route path="project-single/:slug" element={<ProjectSinglePage />} />      
          <Route path='contact' element={<ContactPage />} />
          <Route path='404' element={<ErrorPage />} />
          <Route path='brands' element={<Brands />} />
          <Route path='gallery' element={<Gallerypage />} />
          <Route path='services' element={<ServicePage />} />          
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
