import React, { useState } from 'react';
import Projects from '../../api/project'
import { Link } from "react-router-dom";
import { fetchItem } from '../../utils/fetchItem';
import { environment } from '../../config/environment';



const ProjectSection = (props) => {
   const [services, setServices] = useState([])
   fetchItem('/user/services', setServices);
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <section className="case-section separator-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-5 col-lg-5 col-12">
                        <div className="heading-title-s2">
                            <small>what we offer</small>
                            <h2>Our <span>Technical Services</span></h2>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-6 col-12">
                        <div className="case-left-image-wrap">
                            <div className="case-left-image-group">
                                {services.map((item, index) => (
                                    <div key={index} className={`img-item ${activeIndex === index ? 'active' : ''}`}>
                                        <img src={environment.baseUrl + item.image} alt="" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-12">
                        <div className="case-right-items">
                            {services.slice(0,8).map((project, index) => (
                                <div
                                    key={index}
                                    className={`item ${activeIndex === index ? 'active' : ''}`}
                                    onMouseEnter={() => setActiveIndex(index)} >
                                    <div className="item-left">                                       
                                        <h2>{project.title}</h2>                                       
                                    </div>
                                   
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProjectSection;