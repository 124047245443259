import React, { useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import emailjs from "@emailjs/browser";
import { Toaster, toast  } from "react-hot-toast";

export const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
  
    if (validator.allValid()) {
      emailjs
        .sendForm("service_oilzjpo", "template_ozo1dyg", form.current, {
          publicKey: "WuxknxhJhzk_X7nr6",
        })
        .then(
          () => {
            // console.log('SUCCESS!');
            toast.success('Successfully Messaged, Thank you!');
            setForms({
              name: "",
              email: "",
              subject: "",
              phone: "",
              message: "",
            });
          },
          (error) => {
            // console.log('FAILED...', error.text);
            toast.error("Failed to send message.");
          }
        );
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      sendEmail(e); // Trigger email sending only when all fields are valid
    } else {
      validator.showMessages();
    }
  };

  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

//   const submitHandler = (e) => {
//     e.preventDefault();
//     if (validator.allValid()) {
//       validator.hideMessages();
//       setForms({
//         name: "",
//         email: "",
//         subject: "",
//         phone: "",
//         message: "",
//       });
//     } else {
//       validator.showMessages();
//     }
//   };

  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <div className="row">
          <div className="col col-lg-6 col-12">
            <div className="form-field">
              <input
                value={forms.name}
                type="text"
                name="name"
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                className="form-control"
                placeholder="Your Name"
              />
              {validator.message("name", forms.name, "required|alpha_space")}
            </div>
          </div>
          <div className="col col-lg-6 col-12">
            <div className="form-field">
              <input
                value={forms.email}
                type="email"
                name="email"
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                className="form-control"
                placeholder="Your Email"
              />
              {validator.message("email", forms.email, "required|email")}
            </div>
          </div>
          <div className="col col-lg-6 col-12">
            <div className="form-field">
              <input
                value={forms.phone}
                type="phone"
                name="phone"
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                className="form-control"
                placeholder="Your phone"
              />
              {validator.message("phone", forms.phone, "required|phone")}
            </div>
          </div>

          <div className="col col-lg-6 col-12">
            <div className="form-field">
              <input
                value={forms.subject}
                type="text"
                name="subject"
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                className="form-control"
                placeholder="Subject"
              />
              {validator.message("subject", forms.subject, "required|subject")}
            </div>
          </div>

          <div className="col col-lg-12 col-12 comment-area">
            <textarea
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              value={forms.message}
              type="text"
              name="message"
              className="form-control"
              placeholder="Message"
            ></textarea>
            {validator.message("message", forms.message, "required")}
          </div>
          <div className="col col-lg-12 col-12">
            <div className="submit-area">
              <button type="submit" className="btn-style-1">
                Submit Now
              </button>
            </div>
          </div>
        </div>
      </form>
      <Toaster position="center-center" reverseOrder={false} />
    </>
  );
};
export default ContactForm;