import React, { useState } from "react";
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom'
import hero1 from '../../images/hero/slide-1.jpg'
import hero2 from '../../images/hero/slide-2.jpg'
import { fetchItem } from "../../utils/fetchItem";
import { environment } from "../../config/environment";

const Hero2 = () => {
    const [banners, setBanners] = useState([]);
    fetchItem('/user/banners', setBanners);
    return (

        <section className="hero-slider">
            <Swiper
                // install Swiper modules
                modules={[Navigation, A11y]}
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                speed={1800}
                parallax={true}
                navigation
            >
         {banners.map((banner) => (
            <SwiperSlide key={banner._id}>
               <div className="slide-inner slide-bg-image" style={{ backgroundImage:`url(${environment.baseUrl + banner.image})`}}>
                <div className="container">
                    <div className="slide-content">
                        <div data-swiper-parallax="300" className="slide-title">
                            <h2>Welcome to <span> Hydro Power </span> </h2>                                    
                        </div>
                        <div data-swiper-parallax="400" className="slide-text">
                            <p>{banner.caption} </p>
                        </div>
                        <div className="clearfix"></div>
                        <div data-swiper-parallax="500" className="slide-btns">
                            <Link to="/contact" className="btn-style-1">Get A Quote</Link>
                        </div>
                    </div>
                </div>
              </div>
            </SwiperSlide>
                ))}
             ...
            </Swiper>
        </section>
    )
}

export default Hero2;