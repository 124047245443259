import pimg1 from '../images/case/img-1.jpg'
import pimg2 from '../images/case/img-2.jpg'
import pimg3 from '../images/case/img-3.jpg'
import pimg4 from '../images/case/img-4.jpg'
import pimg5 from '../images/case/img-5.jpg'
import pimg6 from '../images/case/img-6.jpg'
import pimg7 from '../images/case/img-7.jpg'
import pimg8 from '../images/case/img-8.jpg'


import psimg1 from '../images/case/img-1.jpg'
import psimg2 from '../images/case/img-2.jpg'
import psimg3 from '../images/case/img-3.jpg'
import psimg4 from '../images/case/img-4.jpg'
import psimg5 from '../images/case/img-5.jpg'
import psimg6 from '../images/case/img-6.jpg'
import psimg7 from '../images/case/img-7.jpg'
import psimg8 from '../images/case/img-8.jpg'


const Projects = [
   {
      Id: '1',
      pImg: pimg1,
      psImg: psimg1,
      title: 'Aluminium Welding'
           
   },
   {
      Id: '2',
      pImg: pimg2,
      psImg: psimg2,
      title: 'Tig Welding' 
   },
   {
      Id: '3',
      pImg: pimg3,
      psImg: psimg3,
      title: 'Pressure Testing'
   },
   {
      Id: '4',
      pImg: pimg4,
      psImg: psimg4,
      title: 'Power Steering Hose Repairing' 
   },
   {
      Id: '5',
      pImg: pimg5,
      psImg: psimg5,
      title: 'Lathe Fabrication Works'
   },
   {
      Id: '6',
      pImg: pimg6,
      psImg: psimg6,
      title: 'AC Hose Repairing'
   },
   {
      Id: '7',
      pImg: pimg7,
      psImg: psimg7,
      title: 'Tube Bending Works' 
   },
   {
      Id: '8',
      pImg: pimg8,
      psImg: psimg8,
      title: 'Brake Tube Assembly'
   },
   
   


]

export default Projects;