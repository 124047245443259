import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Bg from '../../images/about/banner.jpg'
import Aboutinner from '../../components/about2/about-inner';
import GraphSectionS2 from '../../components/GraphSectionS2/GraphSectionS2';
import CtaSection from '../../components/CtaSection/CtaSection';
import TeamSection from '../../components/TeamSection/TeamSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import GallerySection from '../../components/Gallerysection/GallerySection4';

const Gallerypage = () => {
    return (
        <Fragment>
            <div className='page-wrapper'>
                <Navbar2 hclass={'header-style-2'} />
                <PageTitle pageTitle={'Gallery'} pagesub={'Gallery'} Bg={Bg} />
                <div className="about-page">
                <GallerySection />   
                </div>
                <Footer />
                <Scrollbar />
            </div>
        </Fragment>
    )
};
export default Gallerypage;
