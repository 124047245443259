import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Team from '../../api/products'
import { fetchItem } from '../../utils/fetchItem';
import { environment } from '../../config/environment';


const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const TeamSectionS2 = (props) => {

    
    const [categories, setCategories] = useState([]);

    fetchItem('/user/categories', setCategories);

    return (
        <section className="team-section separator-padding">
            <div className="container">
                <div className="team-wrap">
                    <div className="row">
                        {categories?.map((item, index) => (
                            <div className="col-lg-4 col-sm-6 col-12" key={index}>
                                <div className="team-item">
                                    <div className="team-img">
                                        <img src={environment.baseUrl +item?.image} alt="" />
                                    </div>
                                    <div className="team-text">
                                        <h3><Link onClick={ClickHandler} to={`/products/${item?._id}`}>{item?.name?.split('-').join(' ')}</Link></h3>
                                      
                                    </div>
                                   
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TeamSectionS2;