import React, {useState} from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom'
import Team from '../../api/products'
import Bg from '../../images/team/bg.jpg'
import { fetchItem } from '../../utils/fetchItem';
import { environment } from '../../config/environment';


const TeamSection = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    const [categories, setCategories] = useState([]);

    fetchItem('/user/categories', setCategories);

    const settings = {
        dots: false,
        arrows: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        centerMode: true,
        centerPadding: "0",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <section className="team-section section-padding pb-0">
            <div className="container-fluid">
                <div className="team-wrap">
                    <div className="row">
                        <div className="col-12">
                            <div className="heading-title">
                                <small>What We Do ?</small>
                                <h2>Our Products</h2>
                            </div>
                            <div className="team-slider">
                               

                                <Slider {...settings}>
                                    {categories?.map((category, index) => (
                                        <div className="team-item" key={index}>
                                            <div className="team-img">
                                                <img src={environment.baseUrl + category.image} alt="" />
                                            </div>
                                            <div className="team-text">
                                                <h3><Link onClick={ClickHandler} to={`/products`}>{category?.name?.split('-').join(' ')}</Link></h3>                                               
                                                {/* <span><Link onClick={ClickHandler} to={`/products`}>View All Products</Link></span> */}
                                            </div>                                            
                                           
                                        </div>
                                    ))}
                                </Slider>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TeamSection;