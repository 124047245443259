
import React, { useState } from 'react';
import Projects from '../../api/project';
import { Link } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import psimg1 from '../../images/hose/sshose/01.jpg';
import psimg2 from '../../images/hose/sshose/02.jpg';
import psimg3 from '../../images/hose/sshose/03.jpg';
import { fetchItem } from '../../utils/fetchItem';
import { environment } from '../../config/environment';

const GallerySection = (props) => {
    const [galleries, setGalleries] = useState([]);
    fetchItem('/user/gallery', setGalleries);
    const [open, setOpen] = useState(false);

    // Create slides array from galleries
    const slides = galleries.map((project) => ({
        src: environment.baseUrl + project.image,
        // You can add more properties if needed
    }));

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <section className="case-section-s3 separator-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-7 col-12">
                        <div className="heading-title-s2 text-center">                            
                            <h2>
                                {' '}
                                <span>Our Gallery</span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12 sortable-gallery">
                        <div className="case-container case-fancybox masonry-gallery row">
                            {galleries.map((project, index) => (
                                <div className="col-lg-4 col-md-6 col-12 custom-grid" key={index}>
                                    <div className="case-item">
                                        <div className="case-img">
                                            <img src={environment.baseUrl + project.image} alt="" className="img img-responsive" />
                                            <i className="icon-17" onClick={() => setOpen(true)}></i>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Lightbox open={open} close={() => setOpen(false)} slides={slides} />
        </section>
    );
};

export default GallerySection;