import React from 'react'
import { Link } from 'react-router-dom'
import mobileservice from '../../images/van.webp'
import whatsappimg from '../../images/whatsapp_icon.svg'
import ReactWhatsapp from 'react-whatsapp';

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const CtaSection = (props) => {
    return (
        <section className="cta-section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-6 col-12">
                        <div className="cta-title">
                            <h2> <span> 24x7 </span> On-site Mobile Hose Workshop Service Available All Over <span>Abu Dhabi</span>  </h2>
                            <div className="cta-btn">

                                
                            <Link onClick={ClickHandler} to="/contact" className="btn-style-1 m-r50">Contact Us</Link>
                            

                            <div className="wpbtn ">
                                <ReactWhatsapp number="+971 559565822" message="Hi, Hyro Power Oil Field" />
                                <img className='img-responsive' src={whatsappimg} alt="wp" /> 
                                 </div>



                            {/* <Link onClick={ClickHandler} to="https://wa.me/+971 564044370 ?text=%Hi+Hyro%Power%Oild-field%I+have+a+requirment" className="btn-style-1">Call Us</Link> */}
                        </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-12">                      
                        <img className='img-responsive' src={mobileservice} alt="logo" />
                       
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CtaSection;