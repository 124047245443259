import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
// import Services from '../../api/service'
import { axiosClient } from "../../config/axios.config";
import { environment } from "../../config/environment";
import Services from "../../api/service";


const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const ServiceSection = () => {
    const [services, setServices] = useState([]);
    useEffect(() => {
     (async () => {
    await axiosClient.get('/user/services').then((response) => {
            console.log(response.data.data);
            setServices(response.data.data);
        })
     })()
    },[])

    return (
        <section className="service-section separator-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-7 col-12">
                        <div className="heading-title-s2">
                            <small> Services</small>
                            <h2>Our <span> Technical Services </span></h2>
                        </div>
                    </div>
                </div>
                <div className="service-wrap">
                    <div className="row">
                        {services.map((service, srv) => (
                            <div className="col col-xl-12 col-lg-12 col-md-12 col-12" key={srv}>
                                <div className="service-item">
                                    <div className="service-top">
                                        <div className="col col-xl-5">
                                            <img src={environment.baseUrl+service?.image} alt=""/>
                                        </div>
                                        <div className="col col-xl-7">
                                        <div className="service-text">
                                            <h2>{service.title}.</h2>
                                            <p>{service.description}</p>
                                        </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceSection;