import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom'
import Navbar2 from '../../components/Navbar2/Navbar2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Bg from '../../images/page-title/bg-4.jpg'
import { useParams } from 'react-router-dom'
import Footer from '../../components/footer/Footer';
import Team from '../../api/products'
import { fetchItem } from '../../utils/fetchItem';
import { environment } from '../../config/environment';


const TeamSinglePage = (props) => {
    const { slug } = useParams()
    

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [subcategories, setSubCategories] = useState([]);

    fetchItem(`/user/subcategories/${slug}`, setSubCategories);


    return (
        <Fragment>
            <div className='page-wrapper'>
                <Navbar2 hclass={'header-style-2'} />
                <PageTitle pageTitle={subcategories[0]?.parent_category?.name?.split('-').join(' ')} pagesub={'Products'} Bg={Bg} />
                <div className="team-single-page separator-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <div className="team-single-sidebar brder">
                                    <div className="team-item">
                                        <div className="team-img ">
                                            <img src={environment.baseUrl + subcategories[0]?.parent_category?.image} alt="" />
                                        </div>                                        
                                    </div>                                   
                                  
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 ">
                                <div className="team-single-content">
                                    <h2>{subcategories[0]?.parent_category?.name?.split('-').join(' ')}</h2>
                                    <p>{subcategories[0]?.parent_category?.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="team-section separator-padding">
            <div className="container">
                <div className="team-wrap p-0 ">
                    <div className="row">
                        {subcategories?.map((item, index) => (
                            <div className="col-lg-4 col-sm-6 col-12" key={index}>
                                <div className="team-item mb-3 ">
                                    <div className="team-img">
                                        <img src={environment.baseUrl+item?.image} alt="" />
                                    </div>
                                    <div className="team-text">
                                        <h3><Link onClick={ClickHandler} to={`/products/subcategory/${item?._id}`}>{item?.name?.split('-').join(' ')}</Link></h3>
                                        <span>{}</span>
                                    </div>
                                    <div className="right-title">
                                        <h3>{item?.name}</h3>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        
        
         </div>
              
                <Footer />
                <Scrollbar />
            </div>
        </Fragment>
    )
};
export default TeamSinglePage;
