import React, { useState } from "react";
import pimg1 from '../../images/partners/1.png'
import pimg2 from '../../images/partners/2.png'
import pimg3 from '../../images/partners/3.png'
import pimg4 from '../../images/partners/4.png'
import pimg5 from '../../images/partners/5.png'
import pimg6 from '../../images/partners/6.png'
import pimg7 from '../../images/partners/7.png'
import pimg8 from '../../images/partners/8.png'
import pimg9 from '../../images/partners/9.png'
import pimg10 from '../../images/partners/10.png'
import pimg11 from '../../images/partners/11.png'
import pimg12 from '../../images/partners/12.png'
import { fetchItem } from "../../utils/fetchItem";
import { environment } from "../../config/environment";

const partners = [
    {
        pImg: pimg1,
    },
    {
        pImg: pimg2,
    },
    {
        pImg: pimg3,
    },
    {
        pImg: pimg4,
    },
    {
        pImg: pimg5,
    },
    {
        pImg: pimg6,
    },
    {
        pImg: pimg7,
    },
    {
        pImg: pimg8,
    },
    {
        pImg: pimg9,
    },
    {
        pImg: pimg10,
    },
    {
        pImg: pimg11,
    },
    {
        pImg: pimg12,
    },
]


const PartnerSection = (props) => {
    const [brands,  setBrands] = useState([]);
    fetchItem('/user/brands', setBrands);
    return (
        <section className="partner-section">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="partner-left">
                            <div className="heading-title">
                                <small>They Trust Us</small>
                                <h2>Our Brands </h2>
                                <p>Parts Supplied By Leading Manufactures</p>
                                <a href="brands" className="btn-style-1">View all Brands </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="partner-wrap">
                            {brands.map((partner, pitem) => (
                                <div className="partner-item" key={pitem}><i><img src={environment.baseUrl +  partner.image} alt="" /></i></div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PartnerSection;