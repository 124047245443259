import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../images/hydropowerlogo.jpg'

import MobileMenu from '../MobileMenu/MobileMenu';
import HeaderTopbar from '../HeaderTopbar/HeaderTopbar';

const Header2 = (props) => {

    const [menuActive, setMenuState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    return (
        <header className={`header-area ${props.hclass}`}>
            <HeaderTopbar />
            <div id="header-sticky" className="menu-area">
                <div className="container-fluid">
                    <div className="second-menu">
                        <div className="row align-items-center">
                            <div className="col-xl-2 col-lg-3 col-md-7 col-8">
                                <div className="logo">
                                    <Link className='logo-1' onClick={ClickHandler} to="/"><img src={logo} alt="logo" /></Link>
                                    <Link className='logo-2' onClick={ClickHandler} to="/"><img src={logo} alt="logo" /></Link>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-6 col-md-1 col-1 text-right text-xl-right d-none d-lg-block">
                                <div className="main-menu">
                                    <nav id="mobile-menu">
                                        <ul className="nav">
                                            <li className="has-submenu">
                                                <Link onClick={ClickHandler} to="/">Home</Link>                                                
                                            </li>
                                            <li><Link onClick={ClickHandler} to="/about">About</Link></li>
                                            <li><Link onClick={ClickHandler} to="/products">Products</Link></li>
                                            <li><Link onClick={ClickHandler} to="/services">Services</Link></li>
                                            <li><Link onClick={ClickHandler} to="/brands">Brands</Link></li>
                                            <li><Link onClick={ClickHandler} to="/gallery">Gallery</Link></li>
                                             <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-4 col-2 text-left">
                                <div className="header-area-right">
                                    <ul>                                        
                                        <li className="header-right-btn">
                                            <Link onClick={ClickHandler} to="/contact" className="btn-style-1">Get A Quote</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-2">
                                <MobileMenu />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </header>
    )
}

export default Header2;