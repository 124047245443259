import React from 'react'
import { Link } from 'react-router-dom'

const HeaderTopbar = () => {
    return (
        <section className="topbar">
            <h2 className="hidden">some</h2>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col col-lg-2 col-md-12 col-12">
                        <div className="contact-info social">
                            <ul>
                                <li><Link to="/"><i className="icon-35"></i></Link></li>
                                <li><Link to="/"><i className="icon-34"></i></Link></li>
                                <li><Link to="/"><i className="icon-33"></i></Link></li>
                                <li><Link to="/"><i className="icon-32"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-5 col-md-12 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li><i className="icon-31"></i> Call Us: <Link to="tel:025583314">+971 2 558 3314</Link>, <Link to="tel:+971 561888642 ">+971 56 188 8642 </Link> </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-5 col-md-12 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li><i className="icon-29"></i> Mussafah - 44, ABU DHABI</li>
                                <li><i className="icon-30"></i> <Link to="info@hydropoweroilfield.abudhabi">info@hydropoweroilfield.abudhabi</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeaderTopbar;