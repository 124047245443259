import React from 'react'
import abimg from '../../images/about/vision.jpg'
import abimg2 from '../../images/graph/Bar-Chart-Vertical.png'


const GraphSectionS2 = (props) => {

    return (
        <div className="graph-section">
            <div className="graph-left-img">
                <img src={abimg} alt=""/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 offset-lg-7">
                        <div className="graph-text">
                            <div className="heading-title">                                
                                <h3>OUR <span> VISION</span></h3>
                                <p>To be a leader in our industry, respected for our commitment to go above and beyond for our customers and our employees</p>
                            </div>

                            <div className="heading-title">                                
                                <h3>OUR <span> MISSION</span></h3>
                                <p>To be a leader in our industry, respected for our commitment to go above and beyond for our customers and our employees.</p>
                            </div>

                            <div className="heading-title">                                
                                <h3>CORE <span>  VALUES</span></h3>
                                <ul className='corevalue'>
                                    <li>Team Spirit </li>
                                    <li>Professionalism </li>
                                    <li>Efficiency </li>
                                    <li>Flexibility </li>
                                    <li>Health & Well-being in the workplace </li>
                                    <li>Green Ethics </li>
                                </ul>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GraphSectionS2;