import React, { useState } from 'react';
import Projects from '../../api/project'
import { Link, useParams } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import psimg1 from '../../images/hose/sshose/01.jpg'
import psimg2 from '../../images/hose/sshose/02.jpg'
import psimg3 from '../../images/hose/sshose/03.jpg'
import { fetchItem } from '../../utils/fetchItem';
import { environment } from '../../config/environment';



const SingleproductSection = () => {
    const { id } = useParams();
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [product, setProduct] = useState({});

    fetchItem(`/user/products/${id}`, setProduct);

    const [open, setOpen] = React.useState(false);

    return (
        <section className="case-section-s3 separator-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-7 col-12">
                        <div className="heading-title-s2 text-center">
                            <small>{product?.sub_category?.name?.split('-').join(' ')}</small>
                            <h2> <span>{product?.name?.split('-').join(' ')}</span></h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12 sortable-gallery">
                        <div className="case-container case-fancybox masonry-gallery row">

                                <div className="col-lg-4 col-md-6 col-12 custom-grid">
                                    <div className="case-item">
                                        <div className="case-img">
                                            <img src={environment.baseUrl+product?.image} alt="" className="img img-responsive" />
                                            <i className="icon-17" onClick={() => setOpen(true)}></i>
                                        </div>                            
                                    </div>
                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={[
                    { src: environment.baseUrl+product?.image },
                  
                ]}
            />
        </section>
    );
}

export default SingleproductSection;