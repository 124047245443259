import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Bg from '../../images/about/banner.jpg'
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import SingleProduct from '../../components/SingleProduct';

const SingleproductPage = () => {
    return (
        <Fragment>
            <div className='page-wrapper'>
                <Navbar2 hclass={'header-style-2'} />
                <PageTitle pageTitle={'Products'} pagesub={'Products'} Bg={Bg} />
                <div className="about-page">
                <SingleProduct />   
                </div>
                <Footer />
                <Scrollbar />
            </div>
        </Fragment>
    )
};
export default SingleproductPage;
